export const trimString = (string: string, limit: number): string => {
  const dots = string.length > limit ? " ..." : "";
  return string.substring(0, limit) + dots;
};

export const calculateReadingTime = (
  content: string,
  wordsPerMinute = 200,
): string | undefined => {
  if (!content || content === "") {
    return;
  }

  const words = content.split(/\s+/).length;
  const minutes = Math.ceil(words / wordsPerMinute);
  const plural = minutes > 1 ? "s" : "";
  return `${minutes} minute${plural}`;
};

export const formatTextToSlug = (text: string): string => {
  // Remove spaces and replace punctuation with underscores
  const formattedText = text
    .toLowerCase() // Convert to lowercase
    .normalize("NFD") // Normalize Unicode characters (for accents)
    .replace(/[\u0300-\u036F]/g, "") // Remove accents
    .replace(/\s+/g, "_") // Replace spaces with underscores
    .replace(/[^\w\s]/g, "_"); // Replace punctuation with underscores

  return formattedText;
};

export const replaceUnderscoresWithSlash = (str: string): string => {
  return str.replace(/_/g, " / ");
};
